import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";
import { blobStorageUpload, getApplicantUploadUri, postRequest } from "../../api";

export const ApplicantFileUpload = ({id, processId, applicantId, label, emptyDescription, sizeLimit, uri, fileType, attachmentType, action }) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState("");
  const [error, setError] = useState("");

  const uploadFile = async (file) => {
    setError("");

    // 1. get upload uri
    const uploadUri = await getApplicantUploadUri(processId, applicantId, file.name);

    if (uploadUri.uri) {
      setIsUploading(true);

      // 2. upload file to blob storage
      const result = await blobStorageUpload(
        uploadUri.uri,
        file,
        setUploadProgress
      );
      console.log("result from blobStorageUpload");
      console.log(result);
      if (result) {
        // 3. save attachment to database
        const response = await postRequest(uri, { fileName: file.name });
        if (response.uri) {
          action({
            id: response.id,
            fileName: file.name,
            uri: response.uri,
            attachmentType: attachmentType
          });
        }
        else {
          setError(t("Errors.AttachmentSaveError"));
        }
      }
      else {
        setError(t("Errors.ErrorUploading"));
      }
    } else {
      setError(t("Errors.CreateUriError"));
    }
    setIsUploading(false);
  }
  const onInputChange = (event) => {
    event.preventDefault();
    const file = event.target.files?.[0];
    const fileSize = (file?.size ?? 0) / 1024 / 1024;

    if (fileSize > sizeLimit) {
      setError(t("Errors.FileTooBig", { sizeLimit: sizeLimit }));
      return;
    }

    if (file) {
      uploadFile(file).catch(e => {
        console.log(e);
      });
    }
  };

  return (
    <div>
      <div className="text-sm mt-1 group flex items-start">
        <DocumentCheckIcon
          className="-ml-0.5 h-5 w-5 mr-1 pt-0.5 text-gray-400"
          aria-hidden="true"
        />
        { emptyDescription && (
          <span className="truncate mr-2">
            {emptyDescription}
          </span>
        )}
        <label
          htmlFor={id}
          className="text-sm text-mps-ultramarine hover:text-blue-500 hover:cursor-pointer focus-visible:outline-gray-600 underline"
        >
          {label}
        </label>
        <input
          id={id}
          type="file"
          aria-label={label}
          aria-required="true"
          className="sr-only"
          accept={fileType}
          onChange={onInputChange}
          disabled={isUploading}
        />
        {isUploading && (
          <p className="ml-2 text-sm text-gray-600" id="file-upload-progress">
            {uploadProgress} %
          </p>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="file-upload-error">
          {error}
        </p>
      )}
    </div>
  );
};
