import { ArrowDownTrayIcon, ArrowPathIcon, ArrowUpTrayIcon, PlusIcon } from "@heroicons/react/20/solid";
import { classNames } from "../utils/uiUtils";

export const Button = ({ text, action, icon, type = "primary", isDisabled = false, animate = false}) => {
    const primaryClasses = "rounded bg-mps-ultramarine px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600";
    const secondaryClasses = "rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50";
    const softClasses = "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-mps-ultramarine shadow-sm hover:bg-indigo-1";

    const updateSvgCss = animate ? "-ml-0.5 h-5 w-5 animate-spin" : "-ml-0.5 h-5 w-5";

    return (
        <button
            type="button"
            onClick={ action }
            disabled={ isDisabled }
            className={classNames(
                type === "primary" ? primaryClasses : '',
                type === "secondary" ? secondaryClasses : '',
                type === "soft" ? softClasses : '',
                'relative inline-flex items-center gap-x-1.5',
                'disabled:bg-mps-ultramarine/30 disabled:text-white disabled:border-slate-200 disabled:shadow-none'
            )}>
            { icon && icon === 'PlusIcon' && <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> }
            { icon && icon === 'UploadIcon' && <ArrowUpTrayIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> }
            { icon && icon === 'DownloadIcon' && <ArrowDownTrayIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> }
            { icon && icon === 'UpdateIcon' && <ArrowPathIcon className={updateSvgCss} aria-hidden="true" /> }
            { text }
        </button>
    );
};
