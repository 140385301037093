import { useEffect, useState } from "react";
import { ApplicantCard } from "./ApplicantCard";
import { Button } from "../components/Button";
import { useTranslation } from "react-i18next";
import { formatAsIsoDate, formatAsIsoTime, isEmptyArray, isPositiveOrZero } from "../utils/uiUtils";
import { getItemPositionByOrdinal, getNewApplicantObject } from "./ProcessUtils";
import { getOffices, saveProcess } from "../api";
import { useNotifications } from "../NotificationProvider";
import { ImportApplicantsDialog } from "../components/ImportApplicantsDialog";

export function ApplicantsForm({ formData, errors, clearErrorsAction, updateAction, validateAction, addNewApplicantAction, removeApplicantAction, archived }) {
    const { t } = useTranslation();
    const { setNotification } = useNotifications();
    const sectionKey = "applicants";

    const [officesLoading, setOfficesLoading] = useState(true);
    const [offices, setOffices] = useState([]);
    const [showImportDialog, setShowImportDialog] = useState(false);
    const [importingApplicants, setImportingApplicants] = useState(false);

    useEffect( () => {
        const fetchData = async () => {
            await getOffices().then((response) => {
                setOffices(response);
            }).catch((error) => {
                console.log(error);
                setOffices([]);
            }).finally(() => {
                setOfficesLoading(false);
            })
        }
        if (officesLoading) {
            fetchData().catch(console.error);
        }
    }, [offices, officesLoading]);

    const addNewApplicant = (e) => {
        e.preventDefault();
        const maxOrdinal= isEmptyArray(formData.applicants) ? 0 : Math.max(...formData.applicants.map(item => item.ordinal));
        const newItem = getNewApplicantObject(maxOrdinal+1);
        addNewApplicantAction(sectionKey, newItem);
    };

    const removeApplicant = (ordinal) => {
        let position = getItemPositionByOrdinal(formData.applicants, ordinal);
        let updatedItems = formData.applicants.filter(item => item.ordinal !== ordinal);
        removeApplicantAction(sectionKey, updatedItems);
        if (isPositiveOrZero(position)) {
            clearErrorsAction(sectionKey, position);
        }
    }

    const openImportDialog = () => {
        setImportingApplicants(true);
        setShowImportDialog(true);
    }

    const handleSaveImportedApplicants = async (result) => {
        if (result) {
            const existing = formData.applicants.map(item => item.email);
            const updatedData = result.applicants.filter(item => !existing.includes(item.email));

            updatedData.forEach((item) => {
                const maxOrdinal = isEmptyArray(formData.applicants) ? 0 : Math.max(...formData.applicants.map(item => item.ordinal));
                const newItem = getNewApplicantObject(maxOrdinal+1);
                addNewApplicantAction(sectionKey, {...newItem, ...item});
            });
            const updatedApplicants = [...formData.applicants, ...updatedData];
            const updatedFormData = {...formData, applicants: updatedApplicants};
            const response = await saveProcess(updatedFormData);

            if (response?.id) {
                setNotification({
                    id: Date.now(),
                    title: t("Process.Import.SuccessTitle"),
                    type: "success",
                    message: t("Process.Import.SuccessMessage"),
                });
                updateAction(sectionKey, response.applicants);
            }
        } else {
            setNotification({
                id: Date.now(),
                title: "Errors.Title",
                type: "error",
                message: t("Errors.ErrorRefreshingApplicants"),
            });
        }
        setImportingApplicants(false);
    }

    const geApplicantFieldPrefix = (index) => {
        return `applicants[${index}]`;
    }

    const updateApplicant = (applicantData) => {
        const updatedData = formData.applicants.map((item) => (item.ordinal === applicantData.ordinal ?
            { ...item,
                email: applicantData.email,
                phoneNumber: applicantData.phoneNumber,
                firstName: applicantData.firstName,
                lastName: applicantData.lastName,
                languageCode: applicantData.languageCode,
                officeId: applicantData.officeId,
                individualInterviewDate: formatAsIsoDate(applicantData.individualInterviewDate),
                individualInterviewTime: formatAsIsoTime(applicantData.individualInterviewTime),
                individualInterviewBooking: applicantData.individualInterviewBooking,
                individualSimulationDate: formatAsIsoDate(applicantData.individualSimulationDate),
                individualSimulationTime: formatAsIsoTime(applicantData.individualSimulationTime),
                groupSimulationDate: formatAsIsoDate(applicantData.groupSimulationDate),
                groupSimulationTime: formatAsIsoTime(applicantData.groupSimulationTime),
                notes: applicantData.notes,
                invitationEnabled: applicantData.invitationEnabled
            } : item));

        updateAction(sectionKey, updatedData);

        let position = getItemPositionByOrdinal(updatedData, applicantData.ordinal);

        if (isPositiveOrZero(position)) {
            let item= updatedData[position];
            Object.keys(item).forEach(function(key) {
                validateAction(`applicants[${position}].${key}`, item[key]);
            });
        }
    }

    const cardKey = (item) => {
        if (item.candidateId) {
            return `applicant.${item.ordinal}_${item.candidateId}`;
        } else {
            return `applicant.${item.ordinal}`;
        }
    }

    return (
        <>
            <div className="w-full text-base flex items-center justify-between">
                <div className="text-left"></div>
                <div className="flex items-center space-x-4">
                    {!archived && (
                        <>
                            {formData.talentAdoreId && (
                                <>
                                <Button
                                    text={t("Process.Import.RefreshButton")}
                                    icon={ "UpdateIcon" }
                                    type="secondary"
                                    animate={importingApplicants}
                                    action={openImportDialog}
                                />
                                <ImportApplicantsDialog
                                    title={t("Process.Import.Title")}
                                    formData={formData}
                                    action={handleSaveImportedApplicants}
                                    open={showImportDialog}
                                    importing={importingApplicants}
                                    setImportingState={setImportingApplicants}
                                    setOpen={setShowImportDialog}
                                />
                                </>
                            )}
                            <Button
                                text={ t("Process.Applicants.Actions.Add") }
                                icon={ "PlusIcon" }
                                type="secondary"
                                action={ (event) => addNewApplicant(event) }
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="container mx-auto">
                <div className="grid grid-cols-2 gap-8">
                { formData.applicants && formData.applicants.length > 0 && formData.applicants.map((item, index) => (
                    <div key={ `applicant.${cardKey(item)}` }>
                        <ApplicantCard
                            formData={ formData }
                            fieldPrefix={ geApplicantFieldPrefix(index) }
                            errors = { errors }
                            applicant={ item }
                            offices={ offices }
                            removeApplicantAction={ removeApplicant }
                            updateApplicantAction={ updateApplicant }
                            disabled={ archived }
                        />
                    </div>
                ))}
                </div>
            </div>
        </>
    );
}
