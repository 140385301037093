import React from "react";
import AppRoutes from "./AppRoutes";
import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { NotificationProvider } from "./NotificationProvider";
import { useMsal } from "@azure/msal-react";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const App = () => {
  const { instance } = useMsal();
  const userRoles = instance.getActiveAccount()?.idTokenClaims.roles || [];

  const hasAccess = (allowedRoles) => {
    return !allowedRoles || userRoles.some((role) => allowedRoles.includes(role));
  };

  return (
    <NotificationProvider>
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return hasAccess(route.roles) ? <Route key={index} {...rest} element={element} /> : <Route key={index} path="*" element={<Navigate to="/accessDenied" replace={true} />} />;
          })}
        </Routes>  
      </Layout>
    </NotificationProvider>
  );
};

export default App;
