import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { ConfirmDialog } from "../components/ConfirmDialog";

export default function ProcessActions({ saveLabel, saveAction, saveDisabled, publishVisible, publishDisabled, publishAction }) {
    const { t } = useTranslation();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const saveButtonType = publishVisible ? "soft" : "primary";

    const handlePublish = (e) => {
        publishAction(e);
        setShowConfirmDialog(false);
    };

    return (
        <>
            <ConfirmDialog
                title={t("Process.Actions.Publish")}
                confirmButtonLabel={t("Process.Actions.Publish")}
                action={handlePublish}
                open={showConfirmDialog}
                setOpen={setShowConfirmDialog}
                ongoingAction={publishDisabled}
                mode="confirm"
                content={t("Process.Actions.ConfirmPublish")}
            />
            <Button
                text={ saveLabel }
                action={ saveAction }
                isDisabled={ saveDisabled }
                type={ saveButtonType }
            />

            { publishVisible && (
                <Button
                    text={t("Process.Actions.Publish")}
                    action={ () => setShowConfirmDialog(true) }
                    isDisabled={ publishDisabled }
                />
            )}
        </>
    )
}
