import React, { Fragment, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "./LoadingIndicator";
import { importProcess } from '../api';
import { useNotifications } from "../NotificationProvider";

export const ImportApplicantsDialog = ({
  title,
  content,
  open,
  setOpen,
  action,
  formData,
  importing,
  setImportingState
}) => {
  const { t } = useTranslation();
  const { setNotification } = useNotifications();
  const cancelButtonRef = useRef(null);

  const [process, setProcess] = React.useState(false);

  useEffect(() => {
    const fetchApplicants = async () => {
      const importResult = await importProcess(formData.talentAdoreId);
      setImportingState(false);

      if (importResult) {
        let maxOrdinal = formData.applicants?.length > 0 ? Math.max(...formData.applicants.map(item => item.ordinal)) : 0;

        if (Array.isArray(importResult) && importResult.length > 0) {
          const firstEntryJob = importResult[0].job;
          const applicants = importResult.map((item) => {
            const existingApplicant = formData.applicants.find(existing => existing.email === item.email);
            let ordinal;
            let candidateId;

            if (existingApplicant) {
              ordinal = existingApplicant.ordinal;
              candidateId = existingApplicant.candidateId;
            } else {
              maxOrdinal = maxOrdinal + 1;
              ordinal = maxOrdinal;
            }

            return {
              ordinal: ordinal,
              candidateId: candidateId,
              firstName: item.first_name,
              lastName: item.last_name,
              phoneNumber: item.telephone,
              email: item.email,
              language: item.language,
              officeId: 1,
            };
          });

          const job = {
            customer: {
              name: firstEntryJob.name_internal?.split(" / ")[0],
            },
            jobTitle: firstEntryJob.title,
            type: "UpdateProcess",
            applicants: applicants,
          };
          setProcess(job);
        } else {
          setProcess(false);
        }
      } else {
        setNotification({
          id: Date.now(),
          title: "Errors.Title",
          type: "error",
          message: t("Errors.Import.Failure"),
        });
      }
    };

    if (open && importing) {
      fetchApplicants();
    }
  }, [formData, open, t, setNotification, importing, setImportingState]);

  const handleSaveAction = () => {
    action(process);
    setProcess(false);
    setOpen(false);
  };

  const handleCancelAction = () => {
    setProcess(false);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pl-3 pr-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10">
                    <img
                      src="/images/talentadore-logo.jpg"
                      alt="TalentAdore"
                      className="h-12 w-12"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-4 mb-4">
                      <p className="text-sm text-gray-500">{content}</p>

                      {importing && (
                        <div className="container flex align-middle justify-center my-4">
                          <LoadingIndicator type={"small"} />
                        </div>
                      )}

                      {process && (
                        <div className="container flex my-4">
                          <div className="text-sm font-semibold">
                            <p className="mt-2">{t("Process.Import.Applicants")}</p>
                            {process.applicants?.map((item) => (
                              <p key={item.email}>
                                <span className="font-normal">
                                  {item.firstName} {item.lastName} /{" "}
                                  {item.email}
                                </span>
                              </p>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {process.applicants ? (
                    <>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                        onClick={() => handleSaveAction(process.applicants)}
                      >
                        {t("Process.Actions.Save")}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={handleCancelAction}
                        ref={cancelButtonRef}
                      >
                        {t("Actions.Cancel")}
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={handleCancelAction}
                      ref={cancelButtonRef}
                    >
                      {t("Actions.Cancel")}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
