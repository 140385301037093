import React from "react";

const NotificationContext = React.createContext({
  notifications: [],
  setNotification: () => {},
  removeNotification: () => {},
});

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = React.useState([]);

  const setNotification = (notification) => {
    setNotifications([...notifications, notification]);
  };

  const removeNotification = (notification) => {
    setNotifications(notifications.filter((n) => n.id !== notification.id));
  };

  const value = {
    notifications: notifications,
    setNotification: setNotification,
    removeNotification: removeNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  return React.useContext(NotificationContext);
};
