import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { NotificationArea } from "./NotificationArea";
import { Footer } from "./Footer";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className='flex h-screen flex-col'>
        <NavMenu />
        <AuthenticatedTemplate>
            <main className="bg-mps-light-gray flex-1">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-6 mb-24">
                    {this.props.children}
                </div>
            </main>
            <Footer />
        </AuthenticatedTemplate>
        <NotificationArea />
      </div>
    );
  }
}
