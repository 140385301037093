import { useTranslation } from "react-i18next";
import { useState } from "react";
import { isNotBlank } from "../utils/uiUtils";
import { PDFPreviewModal } from "./PDFPreviewModal";
import { GrayPill, GreenPill, WarnPill } from "./Pills";
import {
  DocumentDuplicateIcon,
  DocumentTextIcon
} from "@heroicons/react/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

export default function AssessmentReport({ report }) {
  const { t } = useTranslation();
  const fileAvailable = isNotBlank(report.url) && report.status === 'Available';
  const randomId = crypto.randomUUID();
  const [showModal, setShowModal] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");

  const openModal = (event, pdfUrl) => {
    event.preventDefault();
    setCurrentPdfUrl(pdfUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentPdfUrl("");
  };

  return (
    <div
      className="text-sm font-medium mt-1 group flex items-start"
      key={`assessment_report_${randomId}_key`}
    >
      {report.type === "Combined" ? (
        <DocumentDuplicateIcon
          className="-ml-0.5 h-5 w-5 mr-1 pt-0.5"
          aria-hidden="true"
        />
      ) : (
        <DocumentTextIcon
          className="-ml-0.5 h-5 w-5 mr-1 pt-0.5"
          aria-hidden="true"
        />
      )}
      {fileAvailable ? (
        <a
          href={report.url}
          onClick={(event) => openModal(event, report.url)}
          rel="noreferrer"
          className="text-mps-ultramarine underline mt-0.5"
        >
          {report.name}
        </a>
      ) : (
        <span className="ml-1 mb-1 mt-0.5">{report.name}</span>
      )}
      <span className="ml-1 mr-1 mb-1 mt-0.5">({report.language})</span>
      { isNotBlank(report.directUrl) && (
        <a
          href={report.directUrl}
          rel="noreferrer"
          target={"_blank"}
        >
          <ArrowTopRightOnSquareIcon
            className="-ml-0.5 h-5 w-5 mr-1 pt-1 text-mps-ultramarine hover:cursor-pointer"
            aria-hidden="true"/>
        </a>
      )}
      {report.status === "Available" && (
        <span className="pb-1">
          <GreenPill text={t("Reports.Status.Available")} />
        </span>
      )}
      {report.status === "Pending" && (
          <span className="pb-1">
          <WarnPill text={t("Reports.Status.Pending")} />
        </span>
      )}
      {report.status === "Requested" && (
        <span className="pb-1">
          <WarnPill text={t("Reports.Status.Requested")} />
        </span>
      )}
      {(report.status === "Unavailable" || report.status === "None") && (
        <span className="pb-1">
          <GrayPill text={t("Reports.Status.Unavailable")} />
        </span>
      )}
      {showModal && isNotBlank(currentPdfUrl) && (
        <PDFPreviewModal
          fileUrl={currentPdfUrl}
          onClose={closeModal}
        />
      )}
    </div>
  );
}
